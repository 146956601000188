import React, { useState } from "react";
import Modal from "react-modal";

// 모달의 루트 엘리먼트를 지정해야 합니다
Modal.setAppElement("#root");

// 배포할 때
const apiUrl = 'https://port-0-backend-m058iehh84254446.sel4.cloudtype.app';

// // // 로컬 컴퓨터에서 실행할 때
// const apiUrl = 'http://localhost:8000';

const consultationUrl = `${apiUrl}/consultation-request`;
const correctionUrl = `${apiUrl}/correction-request`;

function App() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTaxModalOpen, setIsTaxModalOpen] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [taxResponseMessage, setTaxResponseMessage] = useState("");
    const [consultationResponseMessage, setConsultationResponseMessage] = useState("");


    // 모달 열기/닫기 함수
    const openConsultationModal = () => {
        setIsModalOpen(true);
    };

    const closeConsultationModal = () => {
        setIsModalOpen(false);
    };

    const openTaxModal = () => {
        setIsTaxModalOpen(true);
    };

    const closeTaxModal = () => {
        setIsTaxModalOpen(false);
    };

    const openSuccessModal = () => {
        setIsSuccessModalOpen(true);
    };

    const closeSuccessModal = () => {
        setIsSuccessModalOpen(false);
    };

    // 기장 상담 요청 함수
    const handleConsultationRequest = async (e) => {
        e.preventDefault();
        const form = e.target;

        const requestData = {
            name1: form.name1.value,
            name : form.name.value,
            saup: form.saup.value,
            phone: form.phone.value,
            consultationContent: form.consultationContent.value,
        };

        try {
            // const response = await fetch("http://localhost:8000/consultation-request", {
            const response = await fetch(consultationUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            setConsultationResponseMessage(data.message);
            openSuccessModal(); // 신청 완료 시 모달 열기
            closeConsultationModal(); // 상담 모달 닫기
        } catch (error) {
            console.error("Error:", error);
        }
    };

    // 세금 신청 요청 함수
    const handleTaxRequest = async (e) => {
        e.preventDefault();
        const form = e.target;

        const requestData = {
            birthday: form.birthday.value,
            name1: form.name1.value,
            phone: form.phone.value,
            name: form.name.value,
            saup: form.saup.value,
            tax: form.tax.value,
            taxyear: form.taxyear.value
        };

        try {
            // const response = await fetch("http://localhost:8000/correction-request", {
            const response = await fetch("https://port-0-backend-m058iehh84254446.sel4.cloudtype.app/correction-request", {
            // const response = await fetch(correctionUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            setTaxResponseMessage(data.message);
            openSuccessModal(); // 신청 완료 시 모달 열기
            closeTaxModal(); // 모달 닫기
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div>
            <h1>상호명</h1>

            <button onClick={openConsultationModal}>기장상담요청하기</button>
            <button onClick={openTaxModal}>경정청구 신청하기</button>

            {/* 기장상담요청하기 모달 */}
            <Modal isOpen={isModalOpen} onRequestClose={closeConsultationModal} style={modalStyles}>
                <h2>기장상담요청</h2>
                <form onSubmit={handleConsultationRequest}>
                    <label>이름:</label>
                    <input type="text" name="name1" placeholder="이름" required />
                    <br />
                    <label>상호:</label>
                    <input type="text" name="name" placeholder="상호" required />
                    <br />
                    <label>사업자등록번호:</label>
                    <input type="text" name="saup" placeholder="사업자등록번호" required />
                    <br />
                    <label>핸드폰번호:</label>
                    <input type="text" name="phone" placeholder="연락처" required />
                    <br />
                    <label>상담내용:</label>
                    <textarea name="consultationContent" placeholder="상담내용" required></textarea>
                    <br />
                    <button type="submit">상담요청</button>
                    <button onClick={closeConsultationModal}>닫기</button>
                </form>
                {consultationResponseMessage && <p>{consultationResponseMessage}</p>}
            </Modal>

            {/* 경정청구 신청하기 모달 */}
            <Modal isOpen={isTaxModalOpen} onRequestClose={closeTaxModal} style={modalStyles}>
                <h2>경정청구 신청하기</h2>
                <form onSubmit={handleTaxRequest}>
                    <label>주민등록번호:</label>
                    <input type="text"name="birthday"  placeholder="YYYYMMDD0123456"  required/>
                    <br />
                    <label>이름:</label>
                    <input type="text" name="name1" placeholder="이름" required />
                    <label>핸드폰번호:</label>
                    <input type="text" name="phone" placeholder="010-1234-5678" required />
                    <br />
                    <label>상호:</label>
                    <input type="text" name="name" placeholder="상호" required />
                    <br />
                    <label>사업자등록번호:</label>
                    <input type="text" name="saup" placeholder="사업자등록번호" required />
                    <br />
                    <label>세목:</label>
                    <input type="text" name="tax"  placeholder="종합소득세" required />
                    <br />
                    <label>연도:</label>
                    <input type="text" name="taxyear"  placeholder="2024" required />
                    <br />
                    <button type="submit">카카오톡 인증하고 국세청 신고자료 보내기</button>
                    <button onClick={closeTaxModal}>닫기</button>
                </form>
                {taxResponseMessage && <p>{taxResponseMessage}</p>}
            </Modal>
            

            {/* 신청 완료 모달 */}
            <Modal isOpen={isSuccessModalOpen} onRequestClose={closeSuccessModal} style={modalStyles}>
                <h2>신청 완료</h2>
                <p>신청이 완료되었습니다.</p>
                <button onClick={closeSuccessModal}>확인</button>
            </Modal>
        </div>
    );
}

// 모달의 스타일을 정의할 수 있습니다.
const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

export default App;
